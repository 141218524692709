@import "../styles/mixins.scss";

$white: #FFFFFF;
$black: #000000;
$bg-dark: #00132B;

$base-bg: $white;
$base-bg-dark: #3e3e3e;
$theme-primary-color: #000000;
$theme-primary-color-dark: #f17641;
$theme-secondary-color: #0FA3D8;
$theme-secondary-color-dark: #3e6df3;
$theme-tertiary-color: #2EA405;
$theme-tertiary-color-dark: #7c9c0b;
$theme-quaternary-color: #005DB4;
$theme-quaternary-color-dark: #4a4a4a;
$theme-quinary-color: #666666;
$theme-quinary-color-dark: #2a2a2a;
$theme-primary2-color: #21b573;
$theme-primary2-color-dark: #0fbf71;
$theme-gradient: linear-gradient(93.28deg, #0FA3D8 0%, #005BAF 98.53%);
$red-1: #FF6969;
$blue-2: #1c283e; // swiper left
$blue-2-hover: rgba(201, 201, 201, 0.3);
$blue-3: #1e3250; // swiper right
$blue-4: #1d2d4e;
$blue-5: #18243a;
$blue-6: #72AAFF;
$blue-7: #4F77F5;
$blue-8: #2d384b;
$blue-9: #001C3F;
$orange: #F66F45;
$violet-dark: #0b113b;
$violet-med: #02094b;
$violet-1: #224977;

$theme-danger-color: #B12A00;
$theme-danger-color-dark: #0fbf71;

$base-text-1: #52CDBE;

$base-text: #FFFFFF;
$base-text-dark: #fcfcfc;
$text-primary-color: null;
$text-primary-color-dark: null;
$text-secondary-color: null;
$text-tertiary-color: null;
$text-tertiary-color-dark: null;
$text-quaternary-color: #1e1d44;
$text-quaternary-color-dark: #aaa9ab;
$text-quinary-color: null;
$text-quinary-color-dark: null;
