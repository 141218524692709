@import "./variables.scss";

// bg
.bg-gradient {
    background: $theme-gradient;
}

.hover\:bg-theme-secondary:hover {
    background-color: var(--base-secondary-color);
}

.bg-blue-2 {
    background-color: var(--blue-2);
}

.bg-white {
    background-color: var(--white);
}

.bg-black {
    background-color: var(--dark);
}

.bg-dark {
    background-color: var(--bg-dark) !important;
}

.bg-blue-gradient {
    background: linear-gradient(128.09deg, rgba(16, 102, 182, 0.2) 0%, rgba(23, 106, 184, 0.096) 112.38%);;
}

.bg-blue-gradient-2 {
    background: linear-gradient(0deg, rgba(0, 12, 77, 0.9), rgba(0, 12, 77, 0.9));
}

.hover\:bg-blue-2:hover {
    background-color: var(--blue-2-hover);
}

.bg-blue-3 {
    background-color: var(--blue-3);
}

.bg-blue-4 {
    background-color: var(--blue-4);
}

.bg-blue-5 {
    background-color: var(--blue-5);
}

.bg-blue-9 {
    background-color: var(--blue-9);
}

.bg-violet-dark {
    background-color: var(--violet-dark);
}

// text
.xs\:text-center {
    @media (max-width: 575px) {
        text-align: center !important;
    }
}
.text-base-text-1 {
    color: $base-text-1;
}

.text-white {
    color: var(--white);
}

.underline {
    text-decoration: underline;
}

.text-dark {
    color: var(--dark);
}

.text-theme-secondary {
    color: var(--base-secondary-color);
}

.text-blue-6 {
    color: var(--blue-6);
}

.text-violet-1 {
    color: var(--violet-1);
}

.text-orange {
    color: var(--orange);
}

.rounded-xl {
    border-radius: 2rem;
}

.uppercase {
    text-transform: uppercase;
}

.text-normal {
    font-weight: 400;
}


.text-medium {
    font-weight: 500;
}

.text-semibold {
    font-weight: 600;
}

.text-bold {
    font-weight: 700;
}

.text-base {
    font-size: 1rem;
}

.text-sm {
    font-size: 0.75rem;
}

.h-64 {
    height: 64px;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-6 {
    margin-top: 2rem;
}

.mt-8 {
   margin-top: 3rem;
}

.px-8 {
    padding-left: 3rem;
    padding-right: 3rem;
}

.min-h-screen {
    min-height: 100vh;
}

.mw-18 {
    max-width: 18rem;
}

.mw-full {
    max-width: 100%;
}

.w-full {
    width: 100%;
}

.mh-16 {
    max-height: 16rem;
}

.h-full {
    height: 100%;
}

.h-10 {
    height: 10rem;
}

.pointer {
    cursor: pointer;
}

.object-cover {
    object-fit: cover;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-evenly {
    justify-content: space-evenly;
}

.justify-end {
    justify-content: end;
}

.items-center {
    align-items: center;
}

.items-stretch {
    align-items: stretch;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.ul-unset {
    list-style: none;
    padding-left: 0;
}

.break-word {
    word-break: break-all;
    word-break: break-word;
}

.border-red {
    border: 1px solid red
}