@import './variables.scss';
@import "./styles/styles.scss";

html {
    scroll-behavior: smooth;
}
.bg {
  position: relative;
  height: 624px;
  width: initial;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 767px){
    background-image: url("/img/Phone-Blinkers.gif");

  }
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 767px){
      object-fit: cover;
      display: none;
      
    }
  }
}
.flex-center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.logo {
  height: 3rem;
}

.logo_r2cloud {
  height: 3rem;
}

.scrollToTop-btn {
  position: fixed !important;
  display: flex;
  justify-content: center;
  align-items: center;
   right: 15px;
  bottom: 80px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #888181;
  cursor: pointer;
  opacity: 0;
  z-index: 100;
  &.show {
    opacity: 1
  }
  span {
    position: relative;
    top: 3px;  
    width: 15px;
    height: 15px;
    border: 2px solid #ffffff;
    border-radius: 1px;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(45deg);
    transform-origin: center;
  }
}

span[class^="ReactSlackChat__unreadNotificationsBadge"]{
  display: none;
}

.navbar {
  width: 100%;
  position: fixed !important;
  top: 0;
  box-shadow: none !important;
  z-index: 100000;
  background: black;
  .dropdown {
    .dropdown-menu {
      background: #000000;
      .dropdown-item {
          padding: 10px;
          color: #ffffff;
          font-size: 15px;
          &:hover {
              background: #383737;
              cursor: pointer;
            }
        }
    }
  }
  #navItemCollapse {
    .nested-nav-item {
      padding-left: 10px;
    }

  }
}

.navbar.navbar-light .nav-link {
  color: #ffffff !important;
}

.nav-link {
  font-weight: 400;
}

.contactform-container {
    padding-left: 2.5rem;
    position: absolute;
    top: 5rem;
    width: 330px;
}


@media only screen and (max-width: 768px) {
    .phone-form-container {
        text-align: center;
        .iphonex-container {
            position: relative;
            display: inline-block;
        }
    }
    .locations-container {
        padding-top: 50px;
        .address {
            display: block;
            .address-india {
                margin-top: 2rem;
            }
        }
    }
}

.home-iphonex {
  height: 45rem;
}
.mobile-navlink{
    color: #fff !important;
    transition: .35s;
    padding-left: 6px !important;
}

.particles {
  position: absolute;
  top: 0px;
  height: 95vh;
  width: 95%;
}

.splash {
  object-fit: cover;
  height: 100vh;
  width: 100%;
}

.jumbotron {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8) !important;
  line-height: 2;
}

.bg_img {
  height: 70vh;
  width: calc(100%);
  background-size: cover;
}

.address {
  margin-top: 2rem;

  b {
    color: #087ca5;
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    margin: 0px;
  }
}

.badge {
  margin: 10px;
}

.section {
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ddd;
  line-height: 2;
}

.section:last-child {
  border-bottom: none;
}

.section h5 {
  font-weight: 400;
  line-height: 1.5;
}

.section img {
  height: 250px;
  border-radius: 10%;
}

.section a {
  color: #333;
}

.section .stack {
  text-align: center;
}

.banner-header {
    font-size: $web-font-extralarge !important;
    letter-spacing: 0.5px;
    color: #203c8b;
}
.section-header {
    font-size: $web-font-large !important;
    letter-spacing: 0.5px;
    color: #203c8b;
    margin-bottom: 0px;
}

.section-divider {
    font-size: $web-font-medium !important;
    letter-spacing: 0.5px;
    color: #203c8b;
}

.button-text, .section-sub-header {
    font-size: $web-font-small !important;
    letter-spacing: 0.5px;
    color: #fff;
}

.body-copy-small {
    font-size: $web-font-extrasmall !important;
    letter-spacing: 0.5px;
    color: #fff;
}

.body-copy {
    font-size: $base-font-size !important;
    line-height: 26px;
}

.stack-title {
  text-transform: uppercase;
}

.stack-icon {
  height: 70px !important;
  margin-bottom: 10px;
  border-radius: 0% !important;
}

.uni {
  color: #999999;
}

.content {
  position: absolute;
  top: 45%;
  left: 25%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 350px;
  overflow: hidden;
  font-family: "Lato", sans-serif;
  font-size: 35px;
  line-height: 40px;
  color: #333333;
}
.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 40px;
  padding: 0 40px;
}
.content__container:before {
  content: "[";
  left: 0;
}
.content__container:after {
  content: "]";
  position: absolute;
  right: 0;
}
.content__container:after,
.content__container:before {
  position: absolute;
  top: 0;
  color: #0575e6;
  font-size: 42px;
  line-height: 230px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: opacity;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.content__container__text {
  display: inline;
  float: left;
  margin: 0;
}
.content__container__list {
  margin-top: 0;
  padding-left: 110px;
  text-align: left;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.content__container__list__item {
  line-height: 40px;
  margin: 0;
}

footer.page-footer {
  margin-top: 0px !important;
  z-index: 999;
}


footer.page-footer .footer-copyright {
    color: #333 !important;
    padding-top: 1.5rem;
}

@media screen and (max-width: 767px) {
    .footer-copyright {
      font-size: 13px;
    }
}
footer.page-footer h4 {
  color: var(--base-secondary-color);
}

footer.page-footer a {
  color: #fff !important;
}

footer .social {
  padding-right: 2rem;

  i {
    // color: #1b3c8b;
    color: #ffffff;

    &:hover {
      color: var(--base-secondary-color);
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#home-container {
  // margin-top: 90px;
  height: calc(100% - 90px);
}

.my-2 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}


#splash-container {
  position: absolute;
  height: 60vh;
  width: 100%;
  // padding-top: 100px;
  // top: 90px;
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background: url("/img/splash_bg_dark.jpg");
  background-size: cover;
}

#startup-splash-container {
  position: absolute;
  height: 60vh;
  width: 100%;
  padding-top: 100px;
  // top: 90px;
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background: url("/img/startup-banner.png");
  background-size: cover;
}

.content-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.isometric {
//   max-height: 40rem;
  width: calc(100%);
}

#home-container code {
  color: #000000;
}

#jumbotron-container {
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 60vh;
  padding-top: 100px;
}

#more-container {
  margin-top: 5rem;
  padding-bottom: 5rem;
}

#howitworks-container {
  background: #f2f2f2;
}

#homeContainer .container {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

div[class*="ReactSlackChat__card"] {
  z-index: 1;
  left: 40px;
  width: 50px;
  height: 50px;
  bottom: 10px;
  border-radius: 50px;
}

div[class*="ReactSlackChat__card"] h2 {
  color: transparent;
}

input[class*="ReactSlackChat__chat__input"] {
  width: 100%;
}

.reference-container a {
  color: #aaaaaa;
}

label[class*="ReactSlackChat__attachmentIcon"] {
  bottom: 0px;
}

span[class*="ReactSlackChat__chat__name"] {
  top: 20px;
  right: 20px;
}

.startup h1,
.startup-small h1,
.startup h4,
.startup-small h4 {
  color: #1b3c8b;
  margin-bottom: 1.5rem;
}

.startup-small {
  height: 10rem;
}

.entrepreneur-details {
  margin-top: 2rem;
}

.company {
  height: 6.5rem;
  margin-top: 5rem;
}

h2[class*="ReactSlackChat__transition"] {
  background-image: url("/img/slack_logo.png");
  background-size: 20px;
  display: inline-block;
  width: 30px;
  height: 20px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

div[class*="ReactSlackChat__helpHeader"] {
  background: #087ca5;
  border-radius: 50px;
  height: 50px;
  padding: 15px;
}

div[class*="ReactSlackChat__chatHeader"] {
  border-radius: 20px;
}

span[class*="ReactSlackChat__chat__back"]::before {
  border: 2px solid #ffffff;
  border-right: none;
  border-bottom: none;
}

button[class*="ReactSlackChat__channel__close"] {
  color: #ffffff;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    -webkit-transform: translate3d(0, -75%, 0);
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }
}
@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes change {
  0%,
  12.66%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    -webkit-transform: translate3d(0, -75%, 0);
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
  }
}

.nav-links-container {
  padding: 0;
}

.spacer {
  flex: 1;
}

h2[class^=ReactSlackChat__subText] {
  color: #555555 !important;
  font-size: 16px !important;
  margin-top: 10px !important;
}

.services-container,
.approach-container,
.about-container,
.privacy-policy-container,
.cookie-policy-container,
.terms-container {
  margin-top: 90px;
}

@media (max-width: 767px) {
    .flex-center-column h1 br{
        display: none;
    }
    .banner-para br{
        display: none;
    } 
  .navbar {
    padding: 0.25rem 0.5rem !important;
    justify-content: flex-start !important;
    .spacer {
      display: none;
    }
    .nav-links-container {
      display: none !important;
    }
    .navbar-brand {
      a.nav-link {
        padding-left: 0;
      }
      .logo {
        width: 200px;
        height: auto;
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar {
    .collapse {
      display: none;
    }
  }
}

@media (max-width: 321px) {
  .navbar-brand {
    .logo {
      width: 175px;
    }
  }
}

@media (max-width: 767px) {
  #home-container {
    margin-top: 69px;
    height: calc(100% - 69px);
    #splash-container {
      top: 69px;
      height: 60vh;
    }
    #startup-splash-container {
      top: 69px;
      height: 70vh;
    }
    #jumbotron-container {
      height: 70vh;
    }
    .font-large {
      font-size: 2.5rem;
    }

    .font-medium {
      font-size: 1rem;
    }

    .font-small {
      font-size: 0.8rem;
    }
  }
}

#basicCollapse {
  width: 100%;
  .ham-menu-list {
    list-style: none;
    width: 100%;
    padding-left: 10px;
    li {
      a {
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 410px) {
  #home-container {
    #splash-container {
      height: 80vh;
    }
    #startup-splash-container {
      height: 80vh;
    }
    #jumbotron-container {
      height: 80vh;
    }
  }
}

@media (max-width: 360px) {
  #home-container {
    #splash-container {
      height: 90vh;
    }
    #startup-splash-container {
      height: 90vh;
    }
    #jumbotron-container {
      height: 90vh;
    }
    .font-large {
      font-size: 2rem;
    }

    .font-medium {
      font-size: 0.9rem;
    }

    .font-small {
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 376px) {
  #more-container {
    .info-card-row {
      .info-card-col {
        margin: 10px 0 !important;
      }
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  #more-container {
    .info-card-row {
      margin-left: 15px;
      margin-right: 15px;
      .info-card-col {
        margin: 10px 0;
        flex-basis: 100% !important;
      }
    }
  }
}

/********************* Services styles ***********************************/

.services-container {
  /********** banner section styles  ***************/

  @media screen and (max-width: 768px) {
    margin-top: 69px;
    .bg_img {
      position: relative;
      background-size: cover;
      .content {
        width: 70%;
        top: 10%;
        left: 15%;
        transform: unset;
        .header-container {
          h1 {
            font-size: 1.2rem;
          }
        }
        .content__container {
          .content__container__text {
            font-size: 1.2rem;
          }
          .content__container__list {
            padding-left: 90px;
            .content__container__list__item {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 321px) and (max-width: 768px) {
    .content__container {
      padding: 0 10px;
      &:before,
      &:after {
        line-height: 180px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .content {
      .content__container {
        padding: 0 13px;
        &:before,
        &:after {
          line-height: 178px;
        }
        .content__container__list {
          padding-left: 60px !important;
        }
      }
    }
  }

  /**************** services section(main-content) styles *******************/
  @media screen and (max-width: 767px) {
    .section {
      .second {
        order: 2;
      }
      .service-main-logo-container {
        margin-bottom: 15px;
        &.first {
          order: 1;
        }
        .service-main-logo {
          width: 55%;
          height: auto;
        }
      }
      .stack {
        .stack-icon {
          height: 50px !important;
        }
      }
    }
  }
}

/******************* about section styles **********************/
.about-container {
  @media screen and (max-width: 767px) {
    margin-top: 69px;
    .profile-img {
      height: 9rem !important;
    }
  }
}

/********************* approach container styles *********************/
.approach-container {
  /********** banner section styles  ***************/

  @media screen and (max-width: 768px) {
    margin-top: 69px;
    .bg_img {
      position: relative;
      background-size: cover;
      .content {
        width: 70%;
        top: 10%;
        left: 15%;
        transform: unset;
        .header-container {
          h1 {
            font-size: 1.2rem;
          }
        }
        .content__container {
          .content__container__text {
            font-size: 1.2rem;
          }
          .content__container__list {
            padding-left: 90px;
            .content__container__list__item {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 321px) and (max-width: 768px) {
    .content__container {
      padding: 0 10px;
      &:before,
      &:after {
        line-height: 180px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .content {
      .content__container {
        padding: 0 13px;
        &:before,
        &:after {
          line-height: 178px;
        }
        .content__container__list {
          padding-left: 60px !important;
        }
      }
    }
  }

  /**************** approach container (section-styles) *****************/
  @media screen and (max-width: 767px) {
    .section {
      .second {
        order: 2;
      }
      .approach-main-logo-container {
        margin-bottom: 15px;
        &.first {
          order: 1;
        }

        .approach-main-logo {
          width: 55%;
          height: auto;
        }
      }
    }
  }
}

/********************* contact styles ******************************/
.contact-container {
  margin-top: 90px;
  padding-top: 15px;
  height: calc(100vh - 143px);
  @media screen and (max-width: 767px) {
    height: unset;
    .contact-form-container {
      margin-right: unset !important;
      margin-bottom: 15px;
    }
    .ReactSlackChat__card___3w9UW.ReactSlackChat__transition___9M_ac {
      width: 250px;
      right: 0;
      &.ReactSlackChat__active___1osoX {
        height: 450px;
      }
      .ReactSlackChat__helpHeader___1zfWG {
        h2.ReactSlackChat__transition___9M_ac {
          font-size: 1.5rem;
        }
        h2.ReactSlackChat__subText___1hXWw {
          font-size: 15px;
        }
      }
      .ReactSlackChat__chat__back___xUDcG {
        &:before {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}



.contact-modal {
    text-align: center;
    .accesability-btns{
      display: flex;
      justify-content: flex-end;
      .contact-button{
        min-width: unset;
        cursor: pointer;
      }
    }
    .number-hidden {
        visibility: hidden;
        height: 0px;
        -webkit-transition: height 0.1s linear;
           -moz-transition: height 0.1s linear;
            -ms-transition: height 0.1s linear;
             -o-transition: height 0.1s linear;
                transition: height 0.1s linear;
    }
    
    .number-open {
         height: 50px;
         -webkit-transition: height 0.1s linear;
            -moz-transition: height 0.1s linear;
             -ms-transition: height 0.1s linear;
              -o-transition: height 0.1s linear;
                 transition: height 0.1s linear;
    }
    .contact-button {
        background: #2F3291;
        min-width: 330px;
        border-radius: 30px;
        border: none;
        color: white;
        outline: none;
        padding: 10px 20px;
        margin: 20px;
        cursor: pointer;
        text-align: center;
    }
}
.common-button-container {
    padding-bottom: 20px;
    .common-button {
        background: #398144;
        padding: 2px 20px;
        border-radius: 30px;
        border: none;
        outline: none;
        cursor: pointer;
        color: #fff;;
        text-transform: capitalize;
        font-size: 16px !important;
    }
}

.blue-button {
    background-color: #2e3191 !important;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    outline: none;
    text-transform: none;
    color: #ffffff;
    font-family: Oxygen;
    font-weight: bold;
    letter-spacing: 0.23px;
    padding: 0.84rem 2.14rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.blue-button:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
// .startup-container {
//   .app-banner {
//     /* Text style for "custom app" */
//     color: #ffffff;
//     background-color: black;
//     font-family: "Oxygen Light";
//     font-size: 25px;
//     font-weight: 300;
//     letter-spacing: 0.75px;
//   }
//   .container {
//     max-width: 100%;
//     padding: 0 0 0;
//   }
//   .startup-entrepreneur {
//     background-color: #b3b3b3;
//     .startup-entrepreneur_container {
//       padding: 5rem;
//     }
//     .startup {
//       background-color: white;
//     }
//   }
//   .startup-small {
//     :first-child {
//       text-align: center;
//     }
//   }
// }

.project-card{
  position: relative;
  width: 360px;
  height: 338px;
  float: left;
  border-radius:'15px';
  margin:10px;
}

.project-card div{
  position: absolute;
  bottom: 0;
  left: 0;
  right:0;
  background: white;
  color: black;
  // margin-bottom: 5px;
  font-family: sans-serif;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.3s linear; 
  transition: visibility 0s, opacity 0.3s linear;
  font-size: 20px;
}

.project-card:hover{
  cursor: pointer;
}

.project-card:hover div{
  width: 'inherit';
  padding: 8px 15px;
  visibility: visible;
  opacity: 0.8; 
}

.about-us-bg {
  background-image: url("/img/about-us-bg.png");
  height: 523px;
  width: initial;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.founders-card{
  position: relative;
  width: 234px;
  height: 270px;
  margin: 0 auto;
}

.founders-experience {
  font-size: 1.2rem !important;
  position: absolute;
  right: -50px;
  top: -20px;
  z-index: 99;
}

.founders-card img {
  border-radius: 10px;
}

.founders-card div{
  position: absolute;
  bottom: 0;
  left: 0;
  right:0;
  background-image: linear-gradient(to right, #70DAEC, #2267AC ,#D1C1E2);
  color: black;
  // margin-bottom: 5px;
  font-family: sans-serif;
  opacity: 0; 
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.3s linear; 
  transition: visibility 0s, opacity 0.3s linear;
  font-size: 20px;
  padding: 8px 15px;
}

.founders-card:hover{
  cursor: pointer;
}

.founders-card:hover div{
  width: 'inherit';
  visibility: visible;
  opacity: 1.0; 
}

.team-member-card{
  position: relative;
  width: 296px;
  height: 342px;
  border-radius: 10px;
}

.team-member-card div{
  position: absolute;
  bottom: 0;
  left: 0;
  right:0;
  background-image: linear-gradient(to right, #70DAEC, #2267AC ,#D1C1E2);
  color: black;
  font-family: sans-serif;
  opacity: 0; 
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.3s linear; 
  transition: visibility 0s, opacity 0.3s linear;
  font-size: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px 15px;
  width: 'inherit';
}

.team-member-card:hover{
  cursor: pointer;
}

.team-member-card:hover div{
  visibility: visible;
  opacity: 1.0; 
}

.founder-section{
    position: relative;
}

.founder-element{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  min-height: 360px;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.3s linear; 
  transition: visibility 0s, opacity 0.3s linear;
  opacity: 0; 
}

.show-detail{
  visibility: visible;
  opacity: 1.0; 
}
.loading-spinner {
    width: 100vw;
    height: 100vh;
    img {
        width: 100px;
        margin: 20% 50%;
    }
}

#rc-anchor-container {
  width: 250px !important;
}

.cookie-banner-links {
  display: flex;

  a {
    margin-right: 10px;
    color: '#ffffff';
    font-size: 12px;
  }
}
.more-link {
  color: #006ee5 !important;
}
a.btn-default {
  background-color: #1c8076 !important;
}
a.btn-success, button.btn-success {
 background-color: #008936 !important; 
}
.fwd_container {
  width: 100%;
  position: absolute;
  top:90px;
  z-index: 9;
  // padding-top: 80px;
  a {
      width: 100%;
      padding: 0px;
  }
  .fwd_img {
      width: 100%;
      cursor: pointer;
  }
}
.span-link {
  color:white;
  cursor:pointer;
}

.page_h1 { 
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.page_h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.page_h3 { 
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.page_h4 { 
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.page_h5 { 
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.page_h6 { 
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.hdn-item{
  display: none;
}