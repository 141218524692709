@import "../styles/variables.scss";

:root {
    --base-background: #{$base-bg};
    --bg-dark: #{$bg-dark};
    --base-primary-color: #{$theme-primary-color};
    --base-secondary-color: #{$theme-secondary-color};
    --base-tertiary-color: #{$theme-tertiary-color};
    --base-quaternary-color: #{$theme-quaternary-color};
    --base-quinary-color: #{$theme-quinary-color};
    --base-primary2-color: #{$theme-primary2-color};
    --base-text: #{$base-text};
    --base-text-1: #{$base-text-1};
    --text-quaternary-color: #{$text-quaternary-color};
    --text-danger-color: #{$theme-danger-color};
    --white: #{$white};
    --dark: #{$black};
    --red-1: #{$red-1};
    --blue-2: #{$blue-2};
    --blue-2-hover: #{$blue-2-hover};
    --blue-3: #{$blue-3};
    --blue-4: #{$blue-4};
    --blue-5: #{$blue-5};
    --blue-6: #{$blue-6};
    --blue-7: #{$blue-7};
    --blue-8: #{$blue-8};
    --blue-9: #{$blue-9};
    --orange: #{$orange};
    --violet-dark: #{$violet-dark};
    --violet-med: #{$violet-med};
    --violet-1: #{$violet-1};
}
[data-theme="dark"]{
    --base-background: #{$base-bg-dark};
    --base-primary-color: #{$theme-primary-color-dark};
    --base-secondary-color: #{$theme-secondary-color-dark};
    --base-tertiary-color: #{$theme-tertiary-color-dark};
    --base-quaternary-color: #{$theme-quaternary-color-dark};
    --base-quinary-color: #{$theme-quinary-color-dark};
    --base-primary2-color: #{$theme-primary2-color-dark};
    --base-text: #{$base-text-dark};
    --text-quaternary-color: #{$text-quaternary-color-dark};
    --white: #{$black};
}