body {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Oxygen","Oxygen Light", 'Dosis', sans-serif, "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tilt img {  
  -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
         -ms-transition: all 0.5s ease;
                transition: all 0.5s ease;
     }
.tilt img:hover {
       -webkit-transform: rotate(-10deg) scale(0.9);
          -moz-transform: rotate(-10deg) scale(0.9);
               -o-transform: rotate(-10deg) scale(0.9);
            -ms-transform: rotate(-10deg) scale(0.9);
                }