/* For Font
Usage: @include font-source(14px, #000000, 500, 1.2em);
*/
@mixin font-source($size: false, $color: false, $weight: false,  $lh: false) {
    @if $size { font-size: $size; }
    @if $color { color: $color; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

/* For Transform
Usage: @include transform('translateX','(10px)',webkit moz ms o);
*/
@mixin transform($key, $value, $vendors: webkit moz ms o, $default: true) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + transform}: #{$key}(#{$value});
    }
  }
  @if $default {
    transform: #{$key}(#{$value});
  }
}

/* For Transition
Usage: @include transition('all','0.2s','linear',webkit moz ms o);
*/
@mixin transition($name, $sec, $value, $vendors: webkit moz ms o, $default: true) {
    @if $vendors {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + transition}: #{$name} #{$sec} #{$value};
      }
    }
    @if $default {
      transition: #{$name} #{$sec} #{$value};
    }
  }

/* For positioning
Usage: @include fixed(top 0 left 0);
*/
@mixin position($position, $args) {
  position: $position;
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number {
      #{$o}: nth($args, $i + 1);
    }
  }
  
}

// Positioning helpers
@mixin absolute($args: '') {
  @include position(absolute, $args);
}

@mixin fixed($args: '') {
  @include position(fixed, $args);
}

@mixin relative($args: '') {
  @include position(relative, $args);
}

//Flexbox Mixins
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
       -moz-box-pack: start;
       -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
       -moz-box-pack: end;
       -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
       -moz-box-pack: justify;
       -ms-flex-pack: justify;
  } @else if $value == space-around {
       -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
       -moz-box-pack: $value;
       -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
          justify-content: $value;
}

// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($value: stretch) {
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

// flex-start | flex-end | center | baseline | stretch 
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
       -moz-box-align: start;
       -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
       -moz-box-align: end;
       -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
       -moz-box-align: $value;
       -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
          align-items: $value;
}

// auto | flex-start | flex-end | center | baseline | stretch 
@mixin align-self($value: auto) {
  -webkit-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

// row (default) | row-reverse | column | column-reverse
@mixin flex-direction($direction: row) {
  @if $direction == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
       -moz-box-direction: reverse;
       -moz-box-orient: horizontal;
  } @else if $direction == column {
    -webkit-box-direction: normal;
     -webkit-box-orient: vertical;
       -moz-box-direction: normal;
        -moz-box-orient: vertical;
  } @else if $direction == column-reverse {
    -webkit-box-direction: reverse;
      -webkit-box-orient: vertical;
       -moz-box-direction: reverse;
         -moz-box-orient: vertical;
  } @else {
     -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
        -moz-box-direction: normal;
       -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}


$spacing-xs: 15px;
$spacing-md: calc((100vw - 960px) / 2);
$spacing-lg: calc((100vw - 1140px) / 2);
$spacing-xl: calc((100vw - 1540px) / 2);

@mixin spacing($property) {
  #{$property}: $spacing-xs;

  @media (min-width: 992px) {
    #{$property}: $spacing-md;
  }

  @media (min-width: 1200px) {
    #{$property}: $spacing-lg;
  }

  @media (min-width: 1600px) {
    #{$property}: $spacing-xl;
  }
}

.spaced-pl {
  @include spacing(padding-left);
}

.spaced-pr {
  @include spacing(padding-right);
}

.spaced-py {
  @include spacing(padding-top);
  @include spacing(padding-bottom);
}

.spaced-px {
  @include spacing(padding-left);
  @include spacing(padding-right);
}